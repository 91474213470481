import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { getRemittanceEditData, updateConfig, updatePaymentStatus } from "../../../store/slices/Remittance/remittanceSlice";
import { useEffect, useMemo, useState } from "react";
import { useGetBasicDataQuery } from "../../../store/queries/global";
import { useNavigate } from "react-router-dom";
import { branches } from "../../../store/queries/branches";
import { current } from "@reduxjs/toolkit";
import { useGetRemittanceListDataQuery, useUpdateStatusMutation, useUpdateTableFieldsDataMutation,
  } from "../../../store/queries/remittance";
import { toast } from "react-toastify";
import Select from "react-select";
import { getFormatedDate } from "../../../utils/functions/table";

const useRemittances = ({ dashboard, mainFilter }) => {
  const menuState = useSelector((state) => state.remittance);
  const { showCreateModal } = useSelector((state) => state.remittance);
  const { showEditModal } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateTableFields] = useUpdateTableFieldsDataMutation();
  const [updateStatus] = useUpdateStatusMutation();
  // const { data: formData = {} } = useGetBasicDataQuery();
  const [orderPaymentReason, setOrderPaymentReason] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [statusReason, setStatusReason] = useState("");
  const [errors, setErrors] = useState("");
  const [selectedValues, setSelectedValues] = useState({
    data: {},
    selectedOption: {},
  });
  const [selectedPayment, setSelectedPayment] = useState({
    selectedOption: {},
  });

  const { data: basicData = {} } = useGetBasicDataQuery();

  const [date, setDate] = useState({
    startDate: menuState.start,
    endDate: menuState.end,
    key: "selection",
  });

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetRemittanceListDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    per_page: menuState.currentPageSize,
    page: menuState.currentPage,
    start: getFormatedDate(menuState.start),
    end: getFormatedDate(menuState.end),
    branch_id: menuState.currentBranchFilter,
    status: mainFilter !== "" ? (mainFilter === "completed"
    ? 4 : "") : menuState?.currentMainStatusFilter,
    purpose: menuState?.currentSubStatusFilter,
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    //eslint-disable-next-line
  }, [menuState.clearSelection]);

  const handlePagination = (page) => {
  
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  // hello febin! sugalle?! if suganagi igot veruo?!...

  const handleEditAction = (data) => {
    const tempResponse = mainData?.data?.data.find((item) => {
      return item.branch_id === data?.[0];
    });
    let tempCountry = basicData.data.country.find(
      (item) => item._id === tempResponse?.country_id
    );
    let tempState = basicData.data.state.find(
      (item) => item._id === tempResponse?.state_id
    );
    let tempDistrict = basicData?.data?.district?.find(
      (item) => item._id === tempResponse?.district_id
    );

    const tempResponseData = {
      branch_id: tempResponse?.branch_id,
      phone_number: tempResponse?.phone_number,
      name: tempResponse?.name,
      address: tempResponse?.address,
      country: tempCountry,
      state: tempState,
      district: tempDistrict,
      status: tempResponse?.status,
      email: tempResponse?.email,
      branch_code: tempResponse?.branch_code,
      review_link: tempResponse?.review_link,
    };

    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = tempResponseData;
        state.showCreateModal = true;
        state.is_edit = true;
        state.selectedId = data?.[0];
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const hasCreatePermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [mainData]);

  const hasEditPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasBranchViewPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );
    return permission?.[0]?.can_view ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasChangeStatusPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_change_status")
    );
    return permission?.[0]?.can_change_status ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const paymentStatus = [
    {
      label: "Pending",
      id: 1,
    },
    {
      label: "Completed",
      id: 2,
    },
  ];

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      status: (field, data) => {
        let paymentValue = data["payment_status"] === "Completed" ? 2 : 1;
        let remittance_value = basicData?.data?.remittance_process?.[6]?.value

        let selectVal = basicData?.data?.remittance_status.find(
          (item) =>
            item.value ===
            (data[field] === "In Progress"
              ? 3
              : data[field] === "Completed"
              ? 4
              : data[field] === "Canceled"
              ? 5
              : data[field] === "Pending"
              ? 2 
              : data[field] === "Incomplete"
              ? 1
              : "")
        );

        return selectVal ? (
          <Select
            id="status"
            name="status"
            placeholder={"Select"}
            className={`pro-input lg table-ddl  pro-badge ${
              (data[field] === "Pending" && "badge-pending-outline") ||
              (data[field] === "In Progress" && "badge-inprogress-ouline") ||
              (data[field] === "Completed" &&
                "badge-complete-ouline disabled") ||
              (data[field] === "Canceled" && "badge-cancelled-ouline") ||
              (data[field] === "Incomplete" && "badge-incomplete-outline")
            } `}
            classNamePrefix="pro-input"
            options={basicData?.data?.remittance_status}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.value}
            value={selectVal}
            onChange={(selectedOption) => {
              if (paymentValue === 1 && selectedOption?.value === 4) {
                toast.error(
                  "The remittance cannot be completed while the payment status is pending."
                );
                return;
              }
              if (selectedOption?.value === 5 && (data["status"] !== "Pending" && data["status"] !== "In Progress")) {
                toast.error(
                  "The remittance can be canceled only while the status is Pending or In Progress"
                );
                return;
              }

              if(selectedOption?.value === 3) {
                if(data["status"] !== "Canceled") {
                  toast.error(
                    "The remittance can't be changed to In Progress while the status is other than Canceled"
                  );
                  return;
                }
                else if(data["current_step_value"] < remittance_value) {
                  toast.error(
                    "The remittance can't be changed to Pending"
                  );
                  return;
                }
              }

              if(selectedOption?.value === 2) {
                if(data["status"] !== "Canceled") {
                  toast.error(
                    "The remittance can't be changed to Pending while the status is other than Canceled"
                  );
                  return;
                }
                else if(data["current_step_value"] >= remittance_value) {
                  toast.error(
                    "The remittance can't be changed to Pending"
                  );
                  return;
                }
              }

              if (selectedOption?.name === "Completed") {
                setSelectedValues({
                  data: data,
                  selectedOption: selectedOption,
                });
                setShowConfirmationModal(true);
                return;
              }
              //function to change the order status
              handleOrderStatusChange(data, selectedOption);
            }}
            menuPlacement="auto"
            menuPosition="fixed"
            isDisabled={data["status"] === "Completed" || data["status"] === "Incomplete"}
          />
        ) : (
          <p
            className={`pro-input lg table-ddl  pro-badge pro-mb-0 ${
              (data[field] === "Pending" && "badge-pending-outline") ||
              (data[field] === "In Progress" && "badge-inprogress-ouline") ||
              (data[field] === "Completed" &&
                "badge-complete-ouline disabled") ||
              (data[field] === "Canceled" && "badge-cancelled-ouline") ||
              (data[field] === "Incomplete" && "badge-incomplete-outline")
            } `}
          >
            {data[field]}
          </p>
        );
      },

      payment_status: (field, data) => {
        let paymentValue = data[field] !== "Pending" ? 2 : 1;
        let remittance_value = basicData?.data?.remittance_process?.[7]?.value
        const selectVal = paymentStatus?.find(
          (item) => item?.id === paymentValue
        );
        return !dashboard ? (
          <Select
            id="payment_status"
            name="payment_status"
            placeholder={"Select"}
            className={`pro-input lg ${
              selectVal?.id === 2 ? "pro-badge badge-complete-ouline" : ""
            }`}
            classNamePrefix="pro-input"
            options={paymentStatus}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.id}
            value={selectVal}
            isDisabled={data["payment_status"] === "Completed" || (data["current_step_value"] < remittance_value && data["status"] !== "In Progress")}
            onChange={(selectedOption) => { 
              setOrderPaymentReason(true);
              setOrderId(data?.id);
              setSelectedPayment(selectedOption)
            }}
            menuPlacement="auto"
            menuPosition="fixed"
          />
        ) : (
          <p className="pro-mb-0">
            {paymentValue === 2 ? "Completed" : "Pending"}
          </p>
        );
      },

      remittance_number: (feild, data) => {
        let hasViewOrderDetailsPermission = true;
        return hasViewOrderDetailsPermission ? (
          <p
            className={`pro-mb-0 pro-pnt`}
            onClick={() => {
              // localStorage.setItem(
              //   "currentFilter",
              //   data?.order_type.toLowerCase()
              // );
              localStorage.setItem("remittance_id", data?.id);

              dispatch(getRemittanceEditData(data?.id)).then((response) => {
                if (response?.payload?.success) {
                  // if (hasProductViewPermission) {
                    window.open(
                      `/remittance/remittance-details/${data?.remittance_number}/${data?.id}`
                    );
                  // } 
                }
              });

              sessionStorage.setItem("active", `${data?.id}`);
            }}
          >
            {data?.[feild]}
          </p>
        ) : (
          <p className="pro-mb-0">{data?.[feild]}</p>
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };


  const closePaymentReasonModal = () => {
    setOrderPaymentReason(false);
    setErrors("");
    setStatusReason("");
    setOrderId("");
  };

  const handleSubmitPaymentReviewChange = () => {
    if (statusReason?.length <= 0) {
      setErrors("Payment Review is required");
    } else {
      setErrors("");
      const obj = {
        remittance_id: orderId,
        payment_review: statusReason,
        payment_status: selectedPayment?.id
      };

      updatePaymentStatus(obj).then((res) => {
        if (res?.status === 200) {
          toast.success("Payment status changed successfully");
          refetch();
          setOrderPaymentReason(false);
          setErrors("");
          setStatusReason("");
          setOrderId("");
        } else {
          toast.error("Failed to change  payment status");
          setOrderPaymentReason(false);
          setErrors("");
          setStatusReason("");
          setOrderId("");
        }
      });
    }
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
    setSelectedValues({
      data: {},
      selectedOption: {},
    });
  };

  const handleSubmitOrderComplete = () => {
    handleOrderStatusChange(
      selectedValues?.data,
      selectedValues?.selectedOption
    );
  };

  const handleOrderStatusChange = (data, selectedOption) => {
    let formData = new FormData();
    // formData.append("_method", "PUT");
    formData.append("remittance_id", data?.id);
    formData.append("status", selectedOption?.value);
    updateStatus(formData)
      .then((response) => {
        if (response?.data?.success) {
          toast.success("Status Updated Successfully!");
          refetch?.();
          closeConfirmationModal?.();
        } else if (!response?.data?.success) {
          toast.error("Failed to Update Status!");
          closeConfirmationModal?.();
        } else {
          toast.error("Failed to Update Status!");
          closeConfirmationModal?.();
        }
      })
      .catch(() => {
        toast.error("Failed to Update Status!");
        closeConfirmationModal?.();
      });
  };

  // const toggleStatusById = (status, id) => {
  //   let formData = new FormData();
  //   formData.append("_method", "PUT");
  //   formData.append("branch_id", id);
  //   formData.append("status", status === 1 ? 0 : 1);

  //   updateBranchStatus(formData);
  //   dispatch(
  //     branches.util.updateQueryData(
  //       "getBranchListData",
  //       {
  //         sort_by: menuState.sortBy,
  //         sort_order: menuState.sortOrder,
  //         search: menuState.search,
  //         per_page: menuState.currentPageSize,
  //         page: menuState.currentPage,
  //       },
  //       (cacheEntry) => {
  //         let currentCache = { ...current(cacheEntry) };
  //         const newResult = currentCache.data.data?.data?.map((res) =>
  //           res.branch_id === id
  //             ? {
  //                 ...res,
  //                 status: status === 1 ? 0 : 1,
  //               }
  //             : res
  //         );
  //         currentCache = {
  //           ...currentCache,
  //           data: {
  //             ...currentCache.data,
  //             data: {
  //               ...currentCache.data.data,
  //               data: newResult,
  //             },
  //           },
  //         };
  //         return currentCache;
  //       }
  //     )
  //   );
  // };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.start = startDate;
        state.end = endDate;
      })
    );
  };

  const handleMainStatusFilter = (value) => {
    dispatch(
      updateConfig((state) => {
        state.currentMainStatusFilter = value?.value;
        state.currentPage = 1;
      })
    );
  };

  const handleSubStatusFilter = (value) => {
    dispatch(
      updateConfig((state) => {
        state.currentSubStatusFilter = value?.value;
        state.currentPage = 1;
      })
    );
  };

  const handleBranchFilter = (ids) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentBranchFilter = ids;
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/remittance");
    dispatch(
      updateConfig((state) => {
        if (dashboard) {
          state.currentFilter = null;
          state.currentPage = 1;
        } else {
          state.currentPage = 1;
        }
      })
    );
  };

  return {
    menuState,
    mainData,
    isLoading,
    isFetching,
    paginationOptions,
    hasEditPermission,
    actionOptions,
    showEditModal,
    hasCreatePermission,
    showCreateModal,
    basicData,
    currentPage: menuState.currentPage,
    handleSort,
    handleEditAction,
    closeModal,
    updateTableFields,
    closeEditModal,
    handleCreateClick,
    refetch,
    getRow,
    handlePageSize,
    handlePagination,
    handleActionChange,
    handleSearch,
    showConfirmationModal,
    handleSubmitOrderComplete,
    closeConfirmationModal,
    handleSubmitPaymentReviewChange,
    closePaymentReasonModal,
    setStatusReason,
    orderPaymentReason,
    statusReason,
    errors,
    date,
    handleDateRangeChange,
    handleSubStatusFilter,
    handleMainStatusFilter,
    handleBranchFilter,
    handleDashboardRedirect
  };
};

export default useRemittances;
