import { getAxiosInstance } from "../../../../api";


export const getOrderData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`v1/admin/order/detail?order_id=${id}`);
    
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const exportRemittance = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/remittance/download-form`, {
      params: body
    });
    return response;
  } catch (error) {
    return error.response.data;
  }
};

