import {
  HeadingGroup,
  Image,
  ModalLayout,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import useRemittanceDocumentDetails from "./useRemittanceDocumentDetails";
import EmptyData from "../../../Global/EmptyData";
import Style from "./documentDetails.module.scss";
import Assets from "../../../../assets/Assets";
import OverviewShimmer from "../../../Global/Shimmers/OverviewShimmer";
import { RemittanceImageCard } from "../../../Global/RemittanceImageCard";
import RequestForm from "./RequestForm";
const RemittanceDocumentDetails = () => {
  const {
    mainData,
    isFetching,
    showImageModal,
    imageData,
    alt,
    docId,
    itemStatus,
    note,
    itemRejected,
    rejectReason,
    documentType,
    downloadLoading,
    handleDownloadPdf,
    closeImageModal,
    handleViewImage,
    handleVerification,
    setItemRejected,
    setNote,
    handleRequest,
    RequestData,
    getRow,
    basicData,
    closeModal,
    requestRefetch,
    showCreateModal
  } = useRemittanceDocumentDetails();

  const obj = {
    1: "Passport(Front)",
    2: "Passport(Back)",
    3: "Ticket",
    4: "Visa",
    5: "Other Documents",
    6: "Pan Card",
    7: "Education Loan",
  };

  const statusObj = {
    1: "Pending",
    2: "Approved",
    3: "Rejected",
  };

  const handleStatusIcon = (id) => {
    return statusObj[id];
  };

  return (
    <>
      <h6 className="pro-ttl h6 pro-mb-4 pro-mt-5"></h6>
      {isFetching ? (
        <div className="pro-p-4 pro-w-100">
          <OverviewShimmer />
        </div>
      ) : (
        <>
          <div className="pro-w-100 pro-pb-6">
            <div className="pro-w-100">
              <div className="pro-d-flex pro-flex-wrap pro-gap-5">
                {!mainData?.data?.document_details ? (
                  <div className="pro-w-100">
                    <EmptyData />
                  </div>
                ) : (
                  mainData?.data?.document_details?.map((item, index) => (
                    <div className="pro-w-100" key={index}>
                      {/* Display the document type heading */}
                      <h6 className="pro-fw-medium pro-mb-4">
                        {item.document_type_name
                          .replace("_", " ")
                          .toUpperCase()}
                      </h6>

                      {/* Check if there are any documents */}
                      {item?.documents ? (
                        <div className="pro-d-flex pro-flex-wrap pro-gap-5">
                          {item?.documents?.map((doc, docIndex) => (
                            <div className={`${Style.root}`} key={index}>
                              <div className={`${Style.root_inner}`}>
                                <div
                                  className={`${Style.root_image}`}
                                  onClick={(e) =>
                                    handleViewImage(item, docIndex)
                                  }
                                >
                                  <Image
                                    src={
                                      doc?.thumbnail
                                        ? doc?.thumbnail
                                        : Assets.NO_DATA
                                    }
                                    width={146}
                                    height={123}
                                    alt={alt || `user image`}
                                  />
                                </div>
                                <div
                                  className={`${Style.badge} pro-ps-1 pro-bg-light pro-rounded-3 pro-d-flex pro-items-center`}
                                >
                                  <span
                                    className={`${Style.badge_text} pro-fw-medium`}
                                  >
                                    {handleStatusIcon(doc?.status)}
                                  </span>
                                  <div
                                    className={`pro-check-box rounded-tick ${Style.round_tick}`}
                                  >
                                    <img
                                      alt="badge"
                                      src={`
                                    ${
                                      doc?.status === 1
                                        ? Assets.YELLOWCHECK
                                        : doc?.status === 2
                                        ? Assets.GREENCHECK
                                        : doc?.status === 3
                                        ? Assets.REDCHECK
                                        : ""
                                    }
                                    `}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p>
                          No documents available for{" "}
                          {item.document_type_name
                            .replace("_", " ")
                            .toUpperCase()}
                        </p>
                      )}
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>

          <HeadingGroup
            title={"Document Requested"}
            className={`pro-mb-4`}
            buttonTitle={"Request Document"}
            handleClick={handleRequest}
          />
          {RequestData?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={RequestData?.data?.data || []}
            uniqueID={"id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            showCheckBox={false}
            fields={RequestData?.data?.fields}
            getRow={getRow}
          />
        )}
        </>
      )}

      <ModalLayout
        show={showImageModal}
        handleClose={closeImageModal}
        backdrop="static"
      >
        <RemittanceImageCard
          data={imageData}
          documentType={documentType}
          docId={docId}
          status={itemStatus}
          statusObj={statusObj}
          verification={handleVerification}
          handleClose={closeImageModal}
          itemRejected={itemRejected}
          setItemRejected={setItemRejected}
          note={note}
          setNote={setNote}
          rejectReason={rejectReason}
          handleDownloadPdf={handleDownloadPdf}
          downloadLoading={downloadLoading}
        />
      </ModalLayout>

      <ModalLayout
        show={showCreateModal}
        handleClose={closeModal}
        title={"Additional Document Request"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
      >
        <RequestForm
          setShowform={closeModal} refetch={requestRefetch} basicData={basicData?.data?.remittance_document_type}
        />
      </ModalLayout>
    </>
  );
};

export default RemittanceDocumentDetails;
