export const useItemField = (
  formik,
) => {

  const handleRemoveFieldCS = (index) => {
    const data = formik?.values?.required_documents?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("required_documents", data);
  };

  

  return {
    handleRemoveFieldCS,
  };
};

export default useItemField;
