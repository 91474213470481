import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGetBeneficiaryDetailsQuery } from "../../../../store/queries/remittance";

const useBeneficiaryInfo = () => {
  const dispatch = useDispatch();
  // const mainData = JSON.parse(localStorage?.getItem("remittanceDetails"));
  const { ID } = useParams();

  const { data: mainData, isFetching } = useGetBeneficiaryDetailsQuery({
    remittance_id: localStorage.getItem("remittance_id") ?? ID,
  });

  const label = {
    university: "Name of the University/School",
    name: "Beneficiary Name",
    city: "City",
    bAddress: "Beneficiary Address",
    country: "Country",
    bank_name: "Name of the Beneficiary Bank",
    bank_address: "Address of the Beneficiary Bank",
    bank_city: "City of the Beneficiary Bank",
    bank_country: "Country of the Beneficiary Bank",
    account_no: "Account Number"
  };

  const value = {
    university:
      mainData !== null
        ? `${mainData?.data?.university ?? "Not specified"}`
        : "Not specified",
        name: `${mainData?.data?.name ?? "Not specified"}`,
        city: `${mainData?.data?.city ?? "Not specified"}`,
        bAddress: `${mainData?.data?.address ?? "Not specified"}`,
        country: `${
          mainData?.data?.country_name
            ? `${mainData?.data?.country_name}`
            : "Not specified"
        }`,
    bank_name: `${mainData?.data?.bank ?? "Not specified"}`,
    bank_address: `${
      mainData?.data?.bank_address
        ? `${mainData?.data?.bank_address}`
        : "Not specified"
    }`,
    bank_city: `${
      mainData?.data?.bank_city
        ? `${mainData?.data?.bank_city}`
        : "Not specified"
    }`,
    bank_country: `${
      mainData?.data?.bank_country_name
        ? `${mainData?.data?.bank_country_name}`
        : "Not specified"
    }`,
    account_no: `${mainData?.data?.account_number ?? "Not specified"}`,
    
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  const bankLabel = {
    intermediary_bank: "Intermediary Bank Name",
    intermediary_bank_address: "Intermediary Bank Address",
    code: "Banksort/BSB/ABA/Transit/FED Wire Code",
    swift: "Intermediary Bank Swift",
  };

  const bankValue = {
    intermediary_bank:`${mainData?.data?.intermediary_bank ?? "Not specified"}`,
        intermediary_bank_address: `${mainData?.data?.intermediary_bank_address ?? "Not specified"}`,
        code: `${mainData?.data?.intermediary_bank_sort ?? "Not specified"}`,
        swift: `${mainData?.data?.intermediary_bank_swift ?? "Not specified"}`,
  };

  const bankDetails = Object.keys(bankValue).map((key) => {
    return {
      label: bankLabel[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  return {
    basicDetails,
    bankDetails
  };
};

export default useBeneficiaryInfo;
