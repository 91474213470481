import {
  MultiColumnTable,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import useBeneficiaryInfo from "./useBeneficiaryInfo";
import profileStyle from "./payerInfo.module.scss";

const BeneficiaryInfo = () => {
  const {
    basicDetails,
    bankDetails
  } = useBeneficiaryInfo();
  return (
    <>
      <div className={`col-auto pro-pt-5 pro-pb-6`}>
      <div className={`${profileStyle.order_items_wrap}`}>
                        <MultiColumnTable
                          title={"Beneficiary Details"}
                          data={basicDetails}
                          extraClassNames={`multicol-details_table`}
                        />
                      </div>
                      <div className={`${profileStyle.order_items_wrap}`}>
                        <MultiColumnTable
                          title={"Intermediary Bank Details"}
                          data={bankDetails}
                          extraClassNames={`multicol-details_table`}
                        />
                      </div>
      </div>
    </>
  );
};

export default BeneficiaryInfo;
