import { useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/dist";
import useRouteUtils from "../../../../utils/hooks/useRouteUtils";
import moment from "moment";
import { exportRemittance } from "./api";

const useRemittanceDetailLayout = () => {
  const { checkIfActiveRoute } = useRouteUtils();

  const printRef = useRef();
  const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);
  const [show, setShow] = useState(false);
  const remittanceData = JSON.parse(localStorage?.getItem("remittanceDetails"));
  const { remittanceID, ID } = useParams();
  const globalState = useSelector((state) => state.global);
  const location = useLocation();
  const activeProfile = remittanceID ?? sessionStorage.getItem("active");
  const currentFilter = localStorage.getItem("currentFilter");

  const hasPayerViewPermission = useMemo(() => {
    let permission = remittanceData?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view_payer_info")
    );
    return permission?.[0]?.can_view_payer_info ?? 0;
    // eslint-disable-next-line
  }, [remittanceData]);

  //travel detail tab
  const hasBeneficiaryViewPermission = useMemo(() => {
    let permission = remittanceData?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view_beneficiary")
    );
    return permission?.[0]?.can_view_beneficiary ?? 0;
    // eslint-disable-next-line
  }, [remittanceData]);

  //document detail tab
  const hasDocumentDetailViewPermision = useMemo(() => {
    let permission = remittanceData?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view_document")
    );
    return permission?.[0]?.can_view_document ?? 0;
    // eslint-disable-next-line
  }, [remittanceData]);

  //payment detail tab
  const hasPaymentViewPermission = useMemo(() => {
    let permission = remittanceData?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view_payment")
    );
    return permission?.[0]?.can_view_payment ?? 0;
    // eslint-disable-next-line
  }, [remittanceData]);

  //payment detail tab
  const hasDeclarationViewPermission = useMemo(() => {
    let permission = remittanceData?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view_declaration")
    );
    return permission?.[0]?.can_view_declaration ?? 0;
    // eslint-disable-next-line
  }, [remittanceData]);

  const navigation = [
    hasPayerViewPermission && {
      label: "Payer Info",
      title: "Payer Info",
      link: `/remittance/remittance-details/${activeProfile}/${ID}${window.location.search}`,
      active: checkIfActiveRoute(
        `/remittance/remittance-details/${activeProfile}/${ID}`,
        true
      ),
    },
    hasBeneficiaryViewPermission && {
        label: "Beneficiary Info",
        title: "Beneficiary Info",
        link: `/remittance/remittance-details/beneficiary-info/${activeProfile}/${ID}${window.location.search}`,
        active: checkIfActiveRoute(
          `/remittance/remittance-details/beneficiary-info/${activeProfile}/${ID}`
        ),
      },
    hasDocumentDetailViewPermision && {
        label: `Documents`,
        title: "Documents",
        link: `/remittance/remittance-details/document-details/${activeProfile}/${ID}${window.location.search}`,
        active: checkIfActiveRoute(
          `/remittance/remittance-details/document-details/${activeProfile}/${ID}`
        ),
      },
  hasPaymentViewPermission && {
      label: `Payment Info`,
      title: "Payment Info",
      link: `/remittance/remittance-details/remittance-payments-details/${activeProfile}/${ID}${window.location.search}`,
      active: checkIfActiveRoute(
        `/remittance/remittance-details/remittance-payments-details/${activeProfile}/${ID}`
      ),
    },
    hasDeclarationViewPermission && {
      label: `Declaration Details`,
      title: "Declaration Details",
      link: `/remittance/remittance-details/declaration-info/${activeProfile}/${ID}${window.location.search}`,
      active: checkIfActiveRoute(
        `/remittance/remittance-details/declaration-info/${activeProfile}/${ID}`
      ),
    },
  ].filter(Boolean);

  const newRemittancePermissions = [
    { label: "Payer Info" },
    { label: "Beneficiary Info" },
    { label: "Documents" },
    { label: "Payment Info" },
    { label: "Declaration Details" },
  ];

  const remittanceTabPermission = useMemo(() => {
    let menus = newRemittancePermissions?.flatMap?.((menu) => [menu.label]);
    return navigation
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });

    //eslint-disable-next-line
  }, [globalState?.order_permissions, location.pathname, navigation]);

  const label = {
    user_id: "User ID",
    name: "Name",
    mname: "Middle Name",
    lname: "Last Name",
    email: "Email",
    phone: "Phone",
    panNo: "PAN Number"
  };

  const value = {
    user_id:
      remittanceData !== null
        ? `${remittanceData?.user_unique_id ?? "Not specified"}`
        : "Not specified",
        name: `${remittanceData?.name ?? "Not specified"}`,
    email: `${remittanceData?.email ?? "Not specified"}`,
    phone: `${
      remittanceData?.mobile
        ? `${remittanceData?.mobile}`
        : "Not specified"
    }`,
    
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  const remittanceLabel = {
    rnumber: "Remittance Number",
    date: "Created Date",
    foreign: "Foreign Currency",
    equivalent_inr: "Equivalent INR",
    gst: "GST",
    tcs: "TCS",
    sha_charge: "SHA Charge",
    our_charge: "Our Charge"
  };

  const remittanceValue = {
    rnumber: `${remittanceData?.remittance_number ?? "Not specified"}`,
    date: `${remittanceData?.created_at ?? "Not specified"}`,
    foreign: `${remittanceData?.foreign ?? "Not specified"}`,
    equivalent_inr: `${remittanceData?.equivalent_inr ?? "Not specified"}`,
    gst: `${remittanceData?.gst_amount ?? "Not specified"}`,
    tcs: `${remittanceData?.tcs_amount ?? "Not specified"}`,
    sha_charge: `${remittanceData?.sha_charge ?? "Not specified"}`,
    our_charge: `${remittanceData?.our_charge ?? "Not specified"}`,
    
  };

  const remittanceDetails = Object.keys(remittanceValue).map((key) => {
    return {
      label: remittanceLabel[key],
      value:
        typeof remittanceValue[key] === "string"
          ? remittanceValue?.[key]
          : typeof remittanceValue[key] === "number"
          ? remittanceValue?.[key]
          : remittanceValue?.[key]?.name,
    };
  });

  const studentLabel = {
    fname: "First Name",
    mname: "Middle Name",
    lname: "Last Name",
  };

  const studentValue = {
        fname: `${remittanceData?.student_firstname ?? "Not specified"}`,
        mname: `${remittanceData?.student_middlename ?? "Not specified"}`,
        lname: `${remittanceData?.student_lastname ?? "Not specified"}`,
    
  };

  const studentDetails = Object.keys(studentValue).map((key) => {
    return {
      label: studentLabel[key],
      value:
        typeof studentValue[key] === "string"
          ? studentValue?.[key]
          : typeof studentValue[key] === "number"
          ? studentValue?.[key]
          : studentValue?.[key]?.name,
    };
  });

  const exportRemittanceReport = async () => {
  
    try {
      const response = await exportRemittance({ remittance_id: ID });
  
      if (response?.data?.success && response?.data?.data?.url) {
        const pdfUrl = response.data.data.url;
  
        // Fetch the file as a blob
        const fileResponse = await fetch(pdfUrl);
        if (!fileResponse.ok) throw new Error('Network response was not ok');
        const blob = await fileResponse.blob();
  
        // Create a blob URL and download the file
        const downloadLink = document.createElement("a");
        const fileName = `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.pdf`;
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.setAttribute("download", fileName);
        
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
  
        // Release the object URL
        URL.revokeObjectURL(downloadLink.href);
      } else {
        console.error("Error: PDF URL not found in response.");
      }
    } catch (error) {
      console.error("Error fetching PDF URL:", error);
    }
  };
  
  

  return {
    show,
    activeProfile,
    printRef,
    // profileData,
    basicDetails,
    showDeleteImageModal,
    navigation: remittanceTabPermission,
    remittanceData,
    setShowDeleteImageModal,
    setShow,
    studentDetails,
    remittanceDetails,
    exportRemittanceReport
    // refetch,
  };
};

export default useRemittanceDetailLayout;
