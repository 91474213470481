import { useDispatch, useSelector } from "react-redux";
import { useGetBranchRateDataQuery, useGetBranchRemittanceRateDataQuery } from "../../../../store/queries/branches";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { updateConfig } from "../../../../store/slices/Branches/branchSlice";
import { getRateDetails, getRemittanceRateDetails, updateFormData } from "../../../../store/slices/Branches/branchRateFormSlice";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";

const useBranchRate = () => {
  const dispatch = useDispatch();
  const { branch_id } = useParams();
  const activeProfile = sessionStorage.getItem("active");

  const menuState = useSelector((state) => state.branch);
  const rateState = useSelector((state) => state.branchRateForm);
  const { showCreateModal } = useSelector((state) => state.branch);
  const { showEditModal } = useSelector((state) => state.global);
  const [activeTab, setActiveTab] = useState(rateState?.rateActiveTab);

  const permission = JSON.parse(localStorage.getItem("branchTabPermission"))

  const {
    isFetching,
    isLoading,
    data: mainData = {},
    refetch,
  } = useGetBranchRateDataQuery({
    branch_id: branch_id ?? activeProfile,
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.rateSearch,
    page_size: menuState.currentRatePageSize,
    page: menuState.currentRatePage,
    is_active: menuState.is_active,
  },
  {
    skip: activeTab === "remittance", // Skip if neither branch_id nor activeProfile is available
}
);

const {
  isFetching: isRemittanceFetching,
  isLoading: isRemittanceLoading,
  data: mainRemittanceData = {},
  refetch: remittanceRefetch,
} = useGetBranchRemittanceRateDataQuery({
  branch_id: branch_id ?? activeProfile,
  sort_by: menuState.sortBy,
  sort_order: menuState.sortOrder,
  search: menuState.rateSearch,
  page_size: menuState.currentRatePageSize,
  page: menuState.currentRatePage,
  is_active: menuState.is_active,
},
{
  skip: activeTab === "forex", // Skip if neither branch_id nor activeProfile is available
}
);

useEffect(() => {
  dispatch(
    updateFormData((state) => {
      state.rateActiveTab = activeTab;
      state.clearSelection = true;
      state.activeTab = "Remittance";
    })
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [activeTab]);

  //clearing all the slice states initially
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.scrollPage = 1;
        state.currentFilter = null;
        state.currentPageSize = 10;
        state.currentBranchFilter = [];
        state.clearSelection = false;
        state.queryData = {};
        state.queryStatus = "idle";
        state.sortBy = "";
        state.sortOrder = "desc";
        state.search = "";
        state.showCreateModal = false;
        state.selectedId = "";
        state.selectedItemsDetails = "";
        state.is_edit = false;
        state.showViewModal = false;
      })
    );
    //eslint-disable-next-line
  }, []);

  const hasEditPermission = useMemo(() => {
    let permission = activeTab === "forex" ? mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")) : mainRemittanceData?.data?.permission?.filter((p) =>
        Object.keys(p).includes("can_update"))
    return permission?.[0]?.can_update ?? 0;
    // eslint-disable-next-line
  }, [mainData, mainRemittanceData]);


  const hasViewForexRatePermission = permission.some(
    perm => perm.can_view_forex_currency_rate === 1
);

const hasViewRemittanceRatePermission = permission.some(
  perm => perm.can_view_remittance_currency_rate === 1
);



  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const handleEditAction = (data) => {
    if(activeTab === "forex") {
      dispatch(getRateDetails(data?.[0]));
    }
    else {
      dispatch(getRemittanceRateDetails(data?.[0]));
    }
    

    dispatch(
      updateConfig((state) => {
        // state.selectedItemsDetails = tempResponseData;
        state.showCreateModal = true;
        state.is_edit = true;
        state.selectedId = data?.[0];
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentRatePage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentRatePageSize = page_size;
        state.currentRatePage = 1;
      })
    );
  };
  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.rateSearch = e.target.value;
      })
    );
  };

  const setActiveCurrencyTab = (name) => {
    setActiveTab(name);
  }

  return {
    mainData : activeTab === "forex" ? mainData : mainRemittanceData,
    showCreateModal,
    hasEditPermission,
    menuState,
    isFetching: activeTab === "forex" ? isFetching : isRemittanceFetching,
    currentPage: menuState?.currentRatePage,
    paginationOptions,
    showEditModal,
    isLoading: activeTab === "forex" ? isLoading : isRemittanceLoading,
    handleSearch,
    closeEditModal,
    handlePagination,
    handlePageSize,
    refetch: activeTab === "forex" ? refetch : remittanceRefetch,
    handleSort,
    handleEditAction,
    getRow,
    closeModal,
    setActiveCurrencyTab,
    hasViewRemittanceRatePermission,
    hasViewForexRatePermission
  };
};

export default useBranchRate;
