import React from "react";
import usePaymentDetailsOrder from "./usePaymentDetailsOrder";
import OverviewShimmer from "../../../Global/Shimmers/OverviewShimmer";
import { MultiColumnTable } from "@wac-ui-dashboard/wac_component_library";
import Style from "./paymentDetailsOrder.module.scss";

const PaymentDetailsOrder = () => {
  const { paymentValues, isFetching } = usePaymentDetailsOrder();
  return (
    <div className={Style.overview_main}>
      {isFetching ? (
        <div className="pro-p-4 pro-w-100">
          <OverviewShimmer />
        </div>
      ) : (
        <>
          <div className="pro-py-5">
            <MultiColumnTable
              minWidth={`30%`}
              title={"Payment Details"}
              data={paymentValues}
              extraClassNames={`multicol-details_table pro-pb-5`}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentDetailsOrder;
