import React from "react";
import usePaymentDetails from "./usePaymentDetails";
import { MultiColumnTable } from "@wac-ui-dashboard/wac_component_library";

import Style from "../paymentDetails.module.scss";
const PaymentDetails = () => {
  const { paymentDetails } = usePaymentDetails();
  return (
    <div>
      <MultiColumnTable
        extraClassNames={`multicol-details_table`}
        // title={"Payment Details"}
        data={paymentDetails}
      />
    </div>
  );
};

export default PaymentDetails;
