import React, { useEffect, useState } from "react";
import {
  Input,
} from "@wac-ui-dashboard/wac_component_library";
// import Style from "../addReport.module.scss";
import useItemField from "./useItemField";
import Style from "../documentDetails.module.scss";
import Select from "react-select";
import { useDispatch } from "react-redux";

export const ItemField = ({ formik, itmIndex, fields, isEdit, basicData }) => {
  const { handleRemoveFieldCS} = useItemField(
    formik
  );


  const dispatch = useDispatch();
  return (
    <>
      <div className={`pro-mb-4 ${Style.box_root} `}>
        {formik?.values?.required_documents?.length !== 1 && (
          <button
            className={`${Style.btn_close} btn-close`}
            onClick={() => handleRemoveFieldCS(itmIndex)}
          ></button>
        )}
        <div className={` ${Style.add_more_field}  row gx-2`}>
          <div className="col-md-12">
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Document Type *
              </label>
              <Select
                name="document_type"
                options={basicData} // replace with your document_type options
                getOptionValue={(option) => option?.value}
                getOptionLabel={(option) => option?.name}
                value={basicData?.filter((m) => formik?.values?.required_documents?.[itmIndex]?.document_type === m?.value)}
                className={`pro-input lg ${
                  formik.touched?.required_documents?.[itmIndex]?.document_type &&
                  formik.errors?.required_documents?.[itmIndex]?.document_type &&
                " error"
                }`}
                classNamePrefix={`pro-input`}
                onBlur={formik.handleBlur(`required_documents.${itmIndex}.document_type`)}
                // value={formik.values.required_documents?.[itmIndex]?.document_type}
                
                onChange={(selectedOption) =>
                  formik.setFieldValue(`required_documents.${itmIndex}.document_type`, selectedOption?.value)
                }
              />
              {formik.touched?.required_documents?.[itmIndex]?.document_type &&
                formik.errors?.required_documents?.[itmIndex]?.document_type && (
                <div className="error-text">{formik.errors?.required_documents?.[itmIndex]?.document_type}</div>
              )}
            </div>
          </div>

          

          <div className="col-md-12">
            <Input
              type="text"
              id={`comment`}
              name={`comment`}
              label={`Comment`}
              onBlur={formik.handleBlur(`required_documents.${itmIndex}.comment`)}
              className={`pro-input lg ${
                formik.touched?.required_documents?.[itmIndex]?.comment &&
                formik.errors?.required_documents?.[itmIndex]?.comment &&
                " error"
              }`}
              {...formik.getFieldProps(`required_documents.${itmIndex}.comment`)}
              error={
                formik.touched?.required_documents?.[itmIndex]?.comment &&
                formik.errors?.required_documents?.[itmIndex]?.comment && (
                  <span className="error-text">
                    {formik.errors?.required_documents?.[itmIndex]?.comment}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.required_documents?.[itmIndex]?.comment &&
                formik.errors?.required_documents?.[itmIndex]?.comment && (
                  <span className="error-text">
                    {formik.errors?.required_documents?.[itmIndex]?.comment}
                  </span>
                )
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemField;
