import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ProtectRoute from "../utils/components/ProtectRoute/ProtectRoute";
import useRoutes from "../routes/useRoutes";

const Index = () => {
  const navigate = useNavigate();
  const {
    allowedDashboardComponent,
    allowedBranchComponents,
    allowedConfigureComponents,
    allowedOrderComponents,
    allowedUserComponents,
    allowedRemittanceComponents
  } = useRoutes();

  useEffect(() => {
    if (!localStorage.getItem("USER_ACCESS_TOKEN")) {
      navigate("/login");
    } else {
      if (allowedDashboardComponent?.length > 0) {
        navigate(
          `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedOrderComponents?.length > 0) {
        navigate(`/${allowedOrderComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedUserComponents?.length > 0) {
        navigate(`/${allowedUserComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedBranchComponents?.length > 0) {
        navigate(`/${allowedBranchComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedRemittanceComponents?.length > 0) {
        navigate(`/${allowedRemittanceComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedOrderComponents?.length > 0) {
        navigate(`/${allowedOrderComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedConfigureComponents?.length > 0) {
        navigate(
          `/configure/${
            allowedConfigureComponents?.map((item) => item?.path)?.[0]
          }`
        );
      }
    }
    // eslint-disable-next-line
  }, [allowedDashboardComponent]);

  return (
    <ProtectRoute>
      <Outlet />
    </ProtectRoute>
  );
};

export default Index;
