
import React, { useState } from "react";
import {
  Table,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Button,
} from "@wac-ui-dashboard/wac_component_library";
import EmptyData from "../../../Global/EmptyData";
import useBranchRate from "./useBranchRate";
import { FaSort } from "react-icons/fa";
import BranchRateForm from "./branchRateForm";

const BranchRate = () => {
  const {
    isFetching,
    mainData,
    hasEditPermission,
    currentPage,
    showCreateModal,
    menuState,
    isLoading,
    paginationOptions,
    handleEditAction,
    handleSort,
    getRow,
    handleSearch,
    closeModal,
    refetch,
    handlePagination,
    handlePageSize,
    setActiveCurrencyTab,
    hasViewRemittanceRatePermission,
    hasViewForexRatePermission
  } = useBranchRate();

  const [currentTab, setCurrentTab] = useState('forex');

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
    setActiveCurrencyTab(tab);
  };
  return (
    <>
      <div className={`col-12 pro-pt-5`}>
        <div className="row pro-pb-5 pro-items-center">
          <div className="col-auto">
            <h5 className="pro-ttl pro-mb-5">Rate</h5>
          </div>
          <div className="sub-tabs-wrap">
            <div className="row pro-items-center pro-justify-between  pro-mb-4 ">
              <div className="col pro-d-flex nav-tabs">
              {hasViewForexRatePermission && (
                <Button
                  onClick={() => handleTabChange("forex")}
                  className={currentTab === "forex" ? "active" : ""}
                >
                  Forex
                </Button>
                 )}
          
                 {hasViewRemittanceRatePermission && (
                <Button
                  onClick={() => handleTabChange("remittance")}
                  className={currentTab === "remittance" ? "active" : ""}
                >
                  Remittance
                </Button>
                 )}
              </div>
              <div className="col">
                <SearchFilters
                onSearchInput={handleSearch}
                loading={isLoading}
                showActions={false}www
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                searchInputProps={{ value: menuState?.rateSearch }}
              />
              </div>
            </div>
            <div className="col-auto pro-mb-4">

              {mainData?.data?.data?.data?.length === 0 ? (
                <EmptyData />
              ) : (
                <Table
                  data={mainData?.data?.data?.data || []}
                  uniqueID={"branch_currency_id"}
                  editIcon={<span className="material-symbols-outlined">edit</span>}
                  showCheckBox={hasEditPermission === 1 ? true : true}
                  deletable={false}
                  handleEdit={handleEditAction}
                  clear={menuState?.clearSelection}
                  multiSelect={false}
                  assignable={false}
                  fields={mainData?.data?.fields}
                  SortIcon={<FaSort />}
                  handleSort={handleSort}
                  getRow={getRow}
                  loading={isFetching}
                  perpage={menuState?.currentRatePageSize}
                />
              )}

              {mainData?.data?.data?.data?.length > 0 && (
                <Pagination
                  currentPage={currentPage}
                  defaultValue={paginationOptions?.filter(
                    (item) => item.value === menuState?.currentRatePageSize
                  )}
                  totalPageCount={mainData?.data?.data?.last_page}
                  onPageChange={handlePagination}
                  options={paginationOptions}
                  onActionChange={handlePageSize}
                  center
                />
              )}

              <OffCanvasLayout
                show={showCreateModal}
                handleClose={closeModal}
                title={"Update"}
                closeIcon={<span className="material-symbols-outlined">close</span>}
                backdrop="static"
              >
                <BranchRateForm refetch={refetch} closeModal={closeModal} />
              </OffCanvasLayout>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BranchRate;
