import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { phoneRegExp, emailRegExp } from "../../../../utils/functions/table";
import {
  updateConfig,
  updateUser,
  // createNewEntry,
  // updateData,
} from "../../../../store/slices/user/userSlice";
const useUserForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();
  const profilefileInputRef = useRef(null);
  const { is_edit, selectedId, selectedItemsDetails } = useSelector(
    (state) => state.user
  );

  const [profileImagePreview, setProfileImagePreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.profile_image : ""
  );

  useEffect(() => {
    if (selectedItemsDetails !== "") {
      setProfileImagePreview(selectedItemsDetails?.profile_image ?? "");
    }
  }, [selectedItemsDetails]);

  const validation = Yup.object({
    name: Yup.string()
      .min(2, "The name must be at least 2 characters")
      .max(50, "max 50 characters allowed")
      .required("*Required"),
    dob: Yup.string().required("Date of Birt is Required"),
    email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email")
      .required("*Required"),
    country_code: Yup.mixed().required("Country code is required"),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(7, "The phone number must be between 7 and 14 digits")
      .max(10, "The phone number must be between 7 and 14 digits")
      .required("*Required"),
  });

  const CountryCodeOptions = {
    label: selectedItemsDetails?.country_code || "+91",
    value: selectedItemsDetails?.country_code || "+91",
  };

  const formik = useFormik({
    initialValues: {
      user_id: selectedItemsDetails !== "" ? selectedItemsDetails?.user_id : "",
      name: selectedItemsDetails !== "" ? selectedItemsDetails?.name : "",
      email: selectedItemsDetails !== "" ? selectedItemsDetails?.email : "",
      dob: selectedItemsDetails !== "" ? selectedItemsDetails?.dob : "",
      country_code: CountryCodeOptions,
      phone_number:
        selectedItemsDetails !== "" ? selectedItemsDetails?.phone_number : "",
      status:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.status !== null
            ? selectedItemsDetails?.status
            : 0
          : 0,
    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        user_id: is_edit ? selectedId : "",
        name: values?.name,
        email: values?.email,
        dob: values?.dob,
        country_code: values?.country_code?.value,
        phone_number: values?.phone_number,
        status: +values?.status,
      };
      let formData = new FormData();

      if (values?.profile_image?.name) {
        obj.profile_image = values?.profile_image;
      }

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });
      selectedItemsDetails !== "" && formData.append("_method", "PUT");
      dispatch(updateUser({ data: formData })).then((response) => {
        if (response?.payload?.success) {
          resetForm();
          closeModal?.();
          refetch();
          dispatch(
            updateConfig((state) => {
              state.showCreateModal = false;
            })
          );
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );

          toast.success(response?.payload?.message);
        } else if (!response?.payload?.success) {
          Object.entries(response?.payload?.data).forEach(
            ([fieldName, errorMessage]) => {
              if (fieldName.toString() === "phone_number") {
                formik.setFieldError("phone_number", errorMessage[0]);
              }
              if (fieldName.toString() === "email") {
                formik.setFieldError("email", errorMessage[0]);
              }
            }
          );
        } else toast.error(response?.payload?.message);
      });
    },
  });

  const handleProfilePhoto = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("profile_image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setProfileImagePreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return {
    formik,
    selectedItemsDetails,
    profilefileInputRef,
    profileImagePreview,
    handleCloseModal,
    handleProfilePhoto,
  };
};

export default useUserForm;
