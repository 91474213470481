import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";

const initialState = {
     currentPage: 1,
     currentRatePage: 1,
     scrollPage: 1,
     currentFilter: null,
     currentPageSize: 10,
     currentRatePageSize: 10,
     start: subDays(new Date(), 30),
     end: new Date(),
     clearSelection: false,
     queryData: {},
     queryStatus: "idle",
     sortBy: "",
     sortOrder: "desc",
     search: "",
     rateSearch:"",
     showCreateModal: false,
     selectedId: "",
     selectedItemsDetails: "",
     is_edit: false,
     currentBranchFilter: [],
  currentMainStatusFilter: "",
  currentSubStatusFilter: "",
}

// export const createNewBranch = createAsyncThunk(
//      "/admin/branch/create",
//      async (data, { rejectWithValue }) => {
//           const api = await getAxiosInstance();
//           try {
//                const response = await api.post("/admin/branch/create", data)
//                return response.data
//           } catch (error) {
//                return rejectWithValue(error.response.data)
//           }
//      }
// )

// export const updateBranch = createAsyncThunk(
//      "/admin/branch/update",
//      async ({ data }, { rejectWithValue }) => {
//           const api = await getAxiosInstance();
//           try {
//                const response = await api.post(`admin/branch/update`, data)
//                return response.data;
//           } catch (error) {
//                return rejectWithValue(error.response.data);
//           }
//      }
// )

// export const getAllCurrency = createAsyncThunk(
//      "/admin/branch/currency/all",
//      async (rejectWithValue) => {
//        const api = await getAxiosInstance();
//        try {
//          const response = await api.get(`admin/branch/currency/all`);
//          return response;
//        } catch (error) {
//          return rejectWithValue(error.response.data);
//        }
//      }
//    );

//    export const getBranchCurrency = createAsyncThunk(
//      "/admin/branch/currency/view",
//      async (id, rejectWithValue) => {
//        const api = await getAxiosInstance();
//        try {
//          const response = await api.get(`admin/branch/currency/view?branch_id=${id}`);
//          return response;
//        } catch (error) {
//          return rejectWithValue(error.response.data);
//        }
//      }
//    );


// export const deletePincode = createAsyncThunk(
//      "/admin/branch/pincode/delete",
//      async (body, { rejectWithValue }) => {
//           const api = await getAxiosInstance();
//           try {
//                const response = await api.post(`/admin/branch/pincode/delete`, body)
//                return response.data;
//           } catch (error) {
//                return rejectWithValue(error.response.data);
//           }
//      }
// )

// export const editBranchCurrencyData = createAsyncThunk(
//      '/admin/branch/currency/edit',
//      async (body, { rejectWithValue }) => {
//           const api = await getAxiosInstance();
//           try {
//                const response = await api.post(`/admin/branch/currency/edit`, body)
//                return response.data;
//           } catch (error) {
//                return rejectWithValue(error.response.data);
//           }
//      }
// )

// export const currencySave = createAsyncThunk(
//      "/admin/branch/currency/create",
//      async (params, { rejectWithValue }) => {
//        const api = await getAxiosInstance();
//        try {
//          const response = await api.post(`/admin/branch/currency/create`,params);
//          return response.data;
//        } catch (error) {
//          return rejectWithValue(error.response.data);
//        }
//      }
//    );

   export const updatePaymentStatus = async (body) => {
     const api = await getAxiosInstance();
     try {
       const response = await api.post(
         `/admin/remittance/payment-status-change`,
         body
       );
       return response;
     } catch (error) {
       return error.response.data;
     }
   };


   export const getRemittanceEditData = createAsyncThunk(
     'admin/remittance/view',
     async (data, { rejectWithValue }) => {  
          const api = await getAxiosInstance();
          try {
               const response = await api.get(`/admin/remittance/basic-view?remittance_id=${data}`);
               return response.data;
          } catch (error) {
               return rejectWithValue(error.response.data)
          }
     }
);


export const documentVerification = createAsyncThunk(
     "/admin/remittance/documents/verify",
     async (params, { rejectWithValue }) => {
       const api = await getAxiosInstance();
       try {
         const response = await api.post(`/admin/remittance/review-document`, params);
         return response.data;
       } catch (error) {
         return rejectWithValue(error.response.data);
       }
     }
   );

   export const createDocRequest = createAsyncThunk(
     "new/createDocRequest",
     async (params, { rejectWithValue }) => {
       const api = await getAxiosInstance();
       try {
         const response = await api.post(`/admin/remittance-document/request-add`, params);
         return response;
       } catch (error) {
         return rejectWithValue(error.response.data);
       }
     }
   );



const remittanceSlice = createSlice({
     name: "remittance",
     initialState,
     reducers: {
          updateConfig: (state, action) => {
               action.payload(state);
          }
     },
     extraReducers: (builder) => {
          builder
          .addCase(getRemittanceEditData.pending, (state, action) => {
               state.isEditLoading = true;
               state.error = null;
             })
            .addCase(getRemittanceEditData.fulfilled, (state, action) => {
              state.selectedItemsDetails = action.payload.data;
              state.error = null;
              localStorage.setItem(
               "remittanceDetails",
               JSON.stringify(action.payload.data)
             );
            })
            .addCase(getRemittanceEditData.rejected, (state, action) => {
              state.selectedItemsDetails = null;
              state.error = action.payload;
            });
        },
})

export const { updateConfig } = remittanceSlice.actions;

export default remittanceSlice.reducer;