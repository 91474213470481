import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGetPayerDetailsQuery } from "../../../../store/queries/remittance";

const usePayerInfo = () => {
  const dispatch = useDispatch();
  const { ID } = useParams();

  const { data: mainData, isFetching } = useGetPayerDetailsQuery({
    remittance_id: localStorage.getItem("remittance_id") ?? ID,
  });

  const label = {
    account_number: "account_number",
    panNo: "PAN Number",
    fname: "First Name",
    mname: "Middle Name",
    lname: "Last Name",
    dob: "DOB",
    address: "Address",
    city: "City",
    state: "State",
    country: "Country",
    pincode: "PIN",
    relation_to_student: "Relation to Student",
    email: "Email",
    phone: "Phone",
    
  };

  const value = {
    account_number:
      mainData?.data !== null
        ? `${mainData?.data?.account_number ?? "Not specified"}`
        : "Not specified",
        panNo: `${mainData?.data?.pan_number ?? "Not specified"}`,
        fname: `${mainData?.data?.payer_firstname ?? "Not specified"}`,
        mname: `${mainData?.data?.payer_middlename ?? "Not specified"}`,
        lname: `${mainData?.data?.payer_lastname ?? "Not specified"}`,
        relation_to_student: `${
          mainData?.data?.relation_to_student
            ? `${mainData?.data?.relation_to_student}`
            : "Not specified"
        }`,
        dob: `${mainData?.data?.dob ?? "Not specified"}`,
        address: `${mainData?.data?.address ?? "Not specified"}`,
        city: `${mainData?.data?.city ?? "Not specified"}`,
        state: `${mainData?.data?.state ?? "Not specified"}`,
        country: `${mainData?.data?.country ?? "Not specified"}`,
        pincode: `${mainData?.data?.pincode ?? "Not specified"}`,
    email: `${mainData?.data?.email ?? "Not specified"}`,
    phone: `${
      mainData?.data?.mobile
        ? `${mainData?.data?.mobile}`
        : "Not specified"
    }`,
    
    
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  return {
    basicDetails
  };
};

export default usePayerInfo;
