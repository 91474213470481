import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    const branchIdParams = params?.branch_id
      ? params.branch_id.map((id) => `branch_id[]=${id}`).join("&")
      : "";

    try {
      const response = await api[method](
        `${endpoint}?${getParams("remittance_id")}${getParams("branch_id")}${getParams(
          "branch_currency_id"
        )}${getParams("start")}${getParams(
          "end"
        )}${getParams(
          "filter"
        )}${getParams("sort_by")}&sort_order=${params?.sort_order || "desc"}&page_size=${
          params?.per_page || "10"
        }&${getParams("search")}&${branchIdParams}&page=${params?.page || 1}&${getParams(
          "status"
        )}&${getParams("purpose")}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const remittance = createApi({
  reducerPath: "remittanceApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "remittance",
    "basic",
    "payer",
    "beneficiary",
    "document",
    "declaration",
    "payment",
  ],
  endpoints: (builder) => ({
    getRemittanceListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/remittance/list`,
      }),
      providesTags: ["remittance"],
    }),
    getBasicData: builder.query({
      query: () => ({
        method: "get",
        endpoint: `/admin/basic-data`,
      }),
      providesTags: ["basic"],
    }),

    getPayerDetails: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/remittance/payer-info-view`,
      }),
      // keepUnusedDataFor: 0,
      providesTags: ["payer"],
    }),

    getBeneficiaryDetails: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/remittance/beneficiary-view`,
      }),
      // keepUnusedDataFor: 0,
      providesTags: ["beneficiary"],
    }),

    getDocumentListeData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/remittance/document-view`,
      }),
      // keepUnusedDataFor: 0,
      providesTags: ["document"],
    }),

    getRequestListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/remittance-document/request-list`,
      }),
      // keepUnusedDataFor: 0,
      providesTags: ["document"],
    }),

    getDeclarationDetails: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/remittance/declaration-view`,
      }),
      // keepUnusedDataFor: 0,
      providesTags: ["declaration"],
    }),

    getPaymentDetails: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/remittance/payment-view`,
      }),
      providesTags: ["payment"],
    }),
    
    updateTableFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/user/fields/update`,
      }),
      invalidatesTags: ["remittance"],
    }),
    updateStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/remittance/status-change`,
      }),
      invalidatesTags: ["remittance"],
    }),
  }),
});

export const {
  useGetRemittanceListDataQuery,
  useGetBasicDataQuery,
  useUpdateTableFieldsDataMutation,
  useUpdateStatusMutation,
  useGetDocumentListeDataQuery,
  useGetBeneficiaryDetailsQuery,
  useGetDeclarationDetailsQuery,
  useGetPaymentDetailsQuery,
  useGetRequestListDataQuery,
  useGetPayerDetailsQuery
} = remittance;
