import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  start: subDays(new Date(), 30),
  end: new Date(),
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  productSortBy: "",
  productSortOrder: "desc",
  productCurrentFilter: null,
  search: "",
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
  isTravelLoading: false,
  isDeliveryLoading: false,
  showInvoiceModal: false,
  invoiceLoading: false,
  orderId: "",
  searchProduct: "",
  currentBranchFilter: [],
  currentMainStatusFilter: 1,
  currentSubStatusFilter: "",
  showNoteForm: false,
  orderNoteId: "",
};

export const getTravelDetails = createAsyncThunk(
  "admin/orders/travel-details/view",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/orders/travel-details/view?order_id=${id}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProductList = createAsyncThunk(
  "admin/orders/product/list",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/orders/product/list?order_id=${id}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDocumentList = createAsyncThunk(
  "admin/orders/document",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/admin/orders/document?order_id=${id}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDeliveryDetails = createAsyncThunk(
  "admin/orders/delivery/view",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/orders/delivery/view?order_id=${id}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteData = createAsyncThunk(
  "/admin/orders/product/delete",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/orders/product/delete`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const documentVerification = createAsyncThunk(
  "/admin/orders/documents/verify",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/orders/documents/verify`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTravelDetails.pending, (state, action) => {
        state.isTravelLoading = true;
      })
      .addCase(getTravelDetails.fulfilled, (state, action) => {
        state.isTravelLoading = false;
      })
      .addCase(getTravelDetails.rejected, (state, action) => {
        state.isTravelLoading = false;
      })
      .addCase(getDeliveryDetails.pending, (state, action) => {
        state.isDeliveryLoading = true;
      })
      .addCase(getDeliveryDetails.fulfilled, (state, action) => {
        state.isDeliveryLoading = false;
      })
      .addCase(getDeliveryDetails.rejected, (state, action) => {
        state.isDeliveryLoading = false;
      });
  },
});

export const { updateConfig } = orderSlice.actions;

export default orderSlice.reducer;
