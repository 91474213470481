import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import { generatePdfThumbnail } from "../../../../utils/functions/getPdfUrl";
import moment from "moment";
import { useGetDocumentListeDataQuery, useGetRequestListDataQuery } from "../../../../store/queries/remittance";
import { documentVerification, updateConfig } from "../../../../store/slices/Remittance/remittanceSlice";
import { useGetBasicDataQuery } from "../../../../store/queries/global";
import { useSelector } from "react-redux";

const useRemittanceDocumentDetails = () => {
  const dispatch = useDispatch();
  const { ID } = useParams();

  const [itemRejected, setItemRejected] = useState(false);
  const [note, setNote] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");
  const [docId, setDocId] = useState("");
  const [itemStatus, setItemStatus] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { showCreateModal } = useSelector((state) => state.remittance);

  const { data: basicData = {} } = useGetBasicDataQuery();

  const {
    data: mainData,
    isFetching,
    refetch,
  } = useGetDocumentListeDataQuery({
    remittance_id: ID ?? localStorage.getItem("remittance_id"),
  });

  const {
    data: RequestData = {},
    refetch: requestRefetch
  } = useGetRequestListDataQuery({
    remittance_id: ID ?? localStorage.getItem("remittance_id"),
  });

  const handleViewImage = (item, index) => {
    setItemRejected(false);
    setDocumentType(item?.document_type);
    setDocId(item?.documents?.[index]?.id);
    setRejectReason(item?.documents?.[index]?.review);
    setShowImageModal(() => true);
    setImageData(item?.documents?.[index]?.url);
    setItemStatus(item?.documents?.[index]?.status);
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
    setDocId("");
    setDocumentType("");
  };

  const handleVerification = (id, status, note) => {
    let formData = {
      remittance_id: ID ?? localStorage.getItem("remittance_id"),
      document_id: id,
      status: status,
      review: note,
      document_type: documentType
    };
    dispatch(documentVerification(formData))
      .unwrap()
      .then((result) => {
        if (result?.success) {
          toast.success(result?.message);
        } else {
          toast.error(result?.message);
        }
        if (result) {
          refetch();
          setShowImageModal(() => false);
          setImageData("");
          setDocId("");
          setDocumentType("");
        }
      })
      .catch((err) => {
        toast.error("Failed to verify document");
      });
  };

  const handleDownloadPdf = async (url) => {
    setDownloadLoading(true);
    const parts = url?.split("/");
    const fileName = parts[parts.length - 1];

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setDownloadLoading(false);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `${moment(Date.now()).format(
        "MM_DD_YYYY_HH_mm_ss"
      )}_${fileName}`;
      document.body.appendChild(link);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };


  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleRequest = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };

  return {
    mainData,
    showImageModal,
    imageData,
    docId,
    itemStatus,
    isFetching,
    itemRejected,
    rejectReason,
    note,
    documentType,
    downloadLoading,
    handleDownloadPdf,
    generatePdfThumbnail,
    handleViewImage,
    closeImageModal,
    setItemRejected,
    handleVerification,
    setNote,
    RequestData,
    getRow,
    basicData,
    closeModal,
    requestRefetch,
    handleRequest,
    showCreateModal
  };
};

export default useRemittanceDocumentDetails;
