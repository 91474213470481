import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentFormDataBuy: {},
  currentFormDataSell: {},
  selectedItemsDetails: "",
  selectedId: "",
  rateActiveTab: "forex",
  activeTab: "Buy",
  webId: "",
  completedTabs: {
    Buy: false,
    Sale: false,
    Remittance: false,
  },
  completedPercentage: {
    Remittance: 0,
    Buy: 0,
    Sale: 0,
  },
};

// Thunk to fetch rate details for Forex
export const getRateDetails = createAsyncThunk(
  "admin/branch/currency/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/branch/currency/edit?branch_currency_id=${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk to fetch rate details for Remittance
export const getRemittanceRateDetails = createAsyncThunk(
  "admin/branch/remittance/currency/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/branch/currency/remittance/view?branch_remittance_currency_id=${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk to update rate details
export const updateRateDetails = createAsyncThunk(
  "/admin/branch/currency/update",
  async (values, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.put(`/admin/branch/currency/update`, values);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateRateRemittanceDetails = createAsyncThunk(
  "/admin/branch/currency/remittance/update",
  async (values, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/branch/currency/remittance/update`, values);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const branchRateFormSlice = createSlice({
  name: "branchRateForm",
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    // Handle fulfillment for Forex rate details
    builder.addCase(getRateDetails.fulfilled, (state, action) => {
      if (state.rateActiveTab === "forex") {
        state.selectedItemsDetails = action.payload.data;
      }
    });
    // Handle fulfillment for Remittance rate details
    builder.addCase(getRemittanceRateDetails.fulfilled, (state, action) => {
      if (state.rateActiveTab === "remittance") {
        state.selectedItemsDetails = action.payload.data;
      }
    });
  },
});

export const { updateFormData } = branchRateFormSlice.actions;

export default branchRateFormSlice.reducer;
