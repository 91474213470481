import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { updateConfig } from "../../../../../../store/slices/Branches/branchSlice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import {
  updateFormData,
  updateRateRemittanceDetails,
} from "../../../../../../store/slices/Branches/branchRateFormSlice";
import * as Yup from "yup";
import { useGetBasicDataQuery } from "../../../../../../store/queries/global";

const useRemittanceTab = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const { selectedItemsDetails } = useSelector((state) => state.branchRateForm);
  const { selectedId } = useSelector((state) => state.branch);
  const { data: formData = {} } = useGetBasicDataQuery();

  const validation = Yup.object({

    cash_markup_value: Yup.number().when("cash_markup_type", {
      is: 2,
      then: (schema) =>
        schema
          .min(0, "Value must be at least 0")
          .max(100, "Value must be at most 100"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      cash_rate:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.remittance_details?.Cash
            ? selectedItemsDetails?.remittance_details?.Cash?.rate
            : selectedItemsDetails?.remittance_details?.Cash?.rate
          : "",
      cash_markup_type:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.remittance_details?.Cash
            ? selectedItemsDetails?.remittance_details?.Cash?.markup_type
            : selectedItemsDetails?.remittance_details?.Cash?.markup_type
          : "",
      cash_markup_value:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.remittance_details?.Cash
            ? selectedItemsDetails?.remittance_details?.Cash?.markup_value
            : selectedItemsDetails?.remittance_details?.Cash?.markup_value
          : "",
      cash_forex_rate:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.remittance_details?.Cash
            ? selectedItemsDetails?.remittance_details?.Cash?.total_amount
            : selectedItemsDetails?.remittance_details?.Cash?.total_amount
          : "",

      cash_status:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.remittance_details?.Cash?.status !== null
            ? selectedItemsDetails?.remittance_details?.Cash
              ? selectedItemsDetails?.remittance_details?.Cash?.status
              : selectedItemsDetails?.remittance_details?.Cash?.status
            : 0
          : 0,

          our_charge: selectedItemsDetails !== ""
          ? selectedItemsDetails?.remittance_details?.Cash
            ? selectedItemsDetails?.remittance_details?.Cash?.our_charge
            : selectedItemsDetails?.remittance_details?.Cash?.our_charge
          : "",

          sha_charge: selectedItemsDetails !== ""
          ? selectedItemsDetails?.remittance_details?.Cash
            ? selectedItemsDetails?.remittance_details?.Cash?.sha_charge
            : selectedItemsDetails?.remittance_details?.Cash?.sha_charge
          : "",
      
    },

    validationSchema: validation,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        branch_remittance_currency_id: selectedId,
        product:
          {
            id: selectedItemsDetails?.remittance_details["Cash"]
              ? selectedItemsDetails?.remittance_details["Cash"]?.id
              : selectedItemsDetails?.remittance_details["Cash"]?.id,
            markup_type: values?.cash_markup_type,
            markup_value: +values?.cash_markup_value ?? 0,
            rate: values?.cash_rate,
            total_amount: values.cash_forex_rate,
            sha_charge: values?.sha_charge,
            our_charge: values?.our_charge,
            status: +values?.cash_status ?? 0,
          },
      };

      dispatch(updateRateRemittanceDetails(obj)).then((response) => {
        if (response?.payload?.success) {
          resetForm();
          refetch();
          dispatch(
            updateConfig((state) => {
              state.showCreateModal = false;
            })
          );
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );

          toast.success(response?.payload?.message);
        } else if (!response?.payload?.status) {
          if (response?.payload?.data[`product.total_amount`]) {
            formik.setFieldError(
              "cash_markup_value",
              response?.payload?.data[`product.total_amount`]
            );
          }
        } else formik.setErrors(response?.payload?.message);
      });
    },
  });

  // updating the completed percentage of butfields
  const buyFields = ["cash_markup_value", "our_charge","sha_charge"];

  var completedFields = buyFields?.filter((field) => {
    return !!!formik?.errors[field];
  });

  const completedPercentageBuy =
    (completedFields.length / buyFields.length) * 100;

  useEffect(() => {
    // Dispatch the action to update the Redux state
    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Remittance: completedPercentageBuy,
        };
      })
    );
    //eslint-disable-next-line
  }, [completedPercentageBuy]);

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const setTypeFieldValue = (id) => {
    if (formik.values?.cash_markup_value) {
      handleChangeCashForex(formik.values?.cash_markup_value, id);
    }
  };

  const handleChangeCashForex = (e, id) => {
    if (e.target) {
      formik.setFieldValue("cash_markup_value", e.target.value);
    } else {
      formik.setFieldValue("cash_markup_value", e);
    }

    const percentage = Number(
      e?.target?.value ?? formik.values?.cash_markup_value
    );
    const cashRate = Number(formik.values?.cash_rate) || 0;
    const cashMarkup = Math.abs(percentage) || 0;
    let rate = 0;

    if (id === 1) {
      const formattedRate = percentage.toFixed(4);
      formik.setFieldValue("cash_forex_rate", formattedRate);
    } else if (id === 2) {
      if (percentage > 0) {
        rate = cashRate + cashRate * (cashMarkup / 100);
      } else if (percentage < 0) {
        rate = cashRate - cashRate * (cashMarkup / 100);
      } else {
        rate = cashRate;
      }
      const formattedRate = rate.toFixed(4);
      formik.setFieldValue("cash_forex_rate", formattedRate);
    } else {
      rate = cashRate + percentage;
      const formattedRate = rate.toFixed(4);
      formik.setFieldValue("cash_forex_rate", formattedRate);
    }
  };

  

  return {
    formik,
    formData,
    handleCloseModal,
    handleChangeCashForex,
    setTypeFieldValue,
  };
};

export default useRemittanceTab;
