import {
  MultiColumnTable,
  Image,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import useDeclarationInfo from "./useDeclarationInfo";
import profileStyle from "./declarationInfo.module.scss";
import { ImageCard } from "../../../Global/ImageCard";
import Assets from "../../../../assets/Assets";

const DeclarationInfo = () => {
  const {
    basicDetails,
    mainData,
    handleViewImage,
    showImageModal,
    closeImageModal,
    imageData,
  } = useDeclarationInfo();
  return (
    <>
      <div className={`col-auto pro-pt-5 pro-pb-6`}>
        <div className={`${profileStyle.order_items_wrap}`}>
          <MultiColumnTable
            title={"Declaration Details"}
            data={basicDetails}
            extraClassNames={`multicol-details_table`}
          />
          {mainData?.data?.signature_url && (
            <div className={`${profileStyle.root}`}>
              <div className={`${profileStyle.root_inner}`}>
                <div
                  className={`${profileStyle.root_image}`}
                  onClick={(e) =>
                    handleViewImage(mainData?.data?.signature_url)
                  }
                >
                  <Image
                    src={mainData?.data?.signature_url ?? Assets.NO_DATA}
                    width={146}
                    height={123}
                    alt={`signature`}
                  />
                </div>
                <div className={`pro-pt-3`}>
                  <p className="pro-fw-medium pro-mb-0">Signature of Payer</p>
                </div>
              </div>
            </div>
          )}
          {mainData?.data?.e_signature_file_url && (
            <div className={`${profileStyle.root}`}>
              <div className={`${profileStyle.root_inner}`}>
                <div
                  className={`${profileStyle.root_image}`}
                  onClick={(e) =>
                    handleViewImage(mainData?.data?.e_signature_file_url)
                  }
                >
                  <Image
                    src={mainData?.data?.e_signature_file_url ?? Assets.NO_DATA}
                    width={146}
                    height={123}
                    alt={`e signature`}
                  />
                </div>
                <div className={`pro-pt-3`}>
                  <p className="pro-fw-medium pro-mb-0">Signature Document</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalLayout
        show={showImageModal}
        handleClose={closeImageModal}
        backdrop="static"
      >
        <ImageCard data={imageData} handleClose={closeImageModal} />
      </ModalLayout>
    </>
  );
};

export default DeclarationInfo;
