import { useGetPaymentDetailsQuery } from "../../../../store/queries/orders";
import { useParams } from "react-router-dom";
const usePaymentDetailsOrder = () => {
  const { ID } = useParams();
  const orderId = localStorage?.getItem("product_order_id");
  const { data: paymentDetailData, isFetching } = useGetPaymentDetailsQuery({
    order_id: ID ?? orderId,
  });

  const paymentDetailsLabel = {
    payment_id: "Payment ID",
    payment_mode: "Payment Mode",
    payment_type: "Payment Type",
    transaction_id: "Transaction ID",
    status: "Status",
    total_amount: "Total Amount",
    payment_review: "Payment Review",
    transaction_date: "Transaction Date",
    created_by: "Created By",
  };

  const paymentDetailValue = {
    payment_id: `${paymentDetailData?.data?.payment_id ?? "Not specified"}`,
    payment_mode: `${paymentDetailData?.data?.payment_mode ?? "Not specified"}`,
    payment_type: `${paymentDetailData?.data?.payment_type ?? "Not specified"}`,
    transaction_id: `${
      paymentDetailData?.data?.transaction_id ?? "Not specified"
    }`,
    status: `${paymentDetailData?.data?.status ?? "Not specified"}`,
    total_amount: `${paymentDetailData?.data?.total_amount ?? "Not specified"}`,
    payment_review: `${
      paymentDetailData?.data?.payment_review ?? "Not specified"
    }`,
    transaction_date: `${
      paymentDetailData?.data?.transaction_date ?? "Not specified"
    }`,
    created_by: `${paymentDetailData?.data?.created_by ?? "Not specified"}`,
  };

  const paymentValues = Object.keys(paymentDetailValue).map((key) => {
    return {
      label: paymentDetailsLabel[key],
      value:
        typeof paymentDetailValue[key] === "string"
          ? paymentDetailValue?.[key]
          : typeof paymentDetailValue[key] === "number"
          ? paymentDetailValue?.[key]
          : paymentDetailValue?.[key]?.name,
    };
  });

  return { paymentValues, isFetching };
};

export default usePaymentDetailsOrder;
