import { useParams } from "react-router-dom";
import { useGetPaymentDetailsQuery } from "../../../../store/queries/remittance";
const usePaymentInfo = () => {
  const { ID } = useParams();
  const { data: paymentDetailData, isFetching } = useGetPaymentDetailsQuery({
    remittance_id: localStorage.getItem("remittance_id") ?? ID,
  });

  const paymentDetailsLabel = {
    source: "Source of Fund",
    own_amount: "Own Amount",
    loan: "Education Loan Amount",
    currency: "Currency",
    fc: "FC Amount",
    equivalent: "Equivalent to Rs",
    gst: "GST Amount",
    our_charge: "OURS Charges",
    tcs: "TCS Amount",
    total_amount: "Total Amount to Pay",
  };

  const paymentDetailValue = {
    source: `${paymentDetailData?.data?.source ?? "Not specified"}`,
    own_amount: `${paymentDetailData?.data?.own_amount ?? "Not specified"}`,
    loan: `${paymentDetailData?.data?.loan ?? "Not specified"}`,
    currency: `${
      paymentDetailData?.data?.currency_code ?? "Not specified"
    }`,
    fc: `${paymentDetailData?.data?.fc_amount ?? "Not specified"}`,
    equivalent: `${paymentDetailData?.data?.equivalent_inr ?? "Not specified"}`,
    gst: `${
      paymentDetailData?.data?.gst_amount ?? "Not specified"
    }`,
    our_charge: `${
      paymentDetailData?.data?.our_charge ?? "Not specified"
    }`,
    tcs: `${paymentDetailData?.data?.tcs_amount ?? "Not specified"}`,
    total_amount: `${paymentDetailData?.data?.total_amount ?? "Not specified"}`,
  };

  const paymentValues = Object.keys(paymentDetailValue).map((key) => {
    return {
      label: paymentDetailsLabel[key],
      value:
        typeof paymentDetailValue[key] === "string"
          ? paymentDetailValue?.[key]
          : typeof paymentDetailValue[key] === "number"
          ? paymentDetailValue?.[key]
          : paymentDetailValue?.[key]?.name,
    };
  });

  return { paymentValues, isFetching };
};

export default usePaymentInfo;
