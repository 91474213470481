import {
  MultiColumnTable,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import usePayerInfo from "./usePayerInfo";
import profileStyle from "./payerInfo.module.scss";

const PayerInfo = () => {
  const {
    basicDetails
  } = usePayerInfo();
  return (
    <>
      <div className={`col-auto pro-pt-5 pro-pb-6`}>
      <div className={`${profileStyle.order_items_wrap}`}>
                        <MultiColumnTable
                          title={"Payer Details"}
                          data={basicDetails}
                          extraClassNames={`multicol-details_table`}
                        />
                      </div>
      </div>
    </>
  );
};

export default PayerInfo;
