import React from "react";
import {
  Header,
  NavGroup,
  ProfileSideBar,
  MultiColumnTable,
} from "@wac-ui-dashboard/wac_component_library";
import { Link, Outlet } from "react-router-dom";
import Style from "../../../../pages/Layouts/CommonLayout/commonLayout.module.scss";
import useBranchDetailLayout from "./useBranchDetailLayout";
import ProtectRoute from "../../../../utils/components/ProtectRoute/ProtectRoute";
import HeaderActions from "../../../../pages/Layouts/CommonLayout/HeaderActions";
import useCommonLayout from "../../../../pages/Layouts/CommonLayout/useCommonLayout";
import profileStyle from "./branchDetailLayout.module.scss";
import ProfileLayoutSidebarShimmer from "../../../Global/Shimmers/ProfileLayoutSidebarShimmer";
import Assets from "../../../../assets/Assets";

const BranchDetailLayout = () => {
  const { isFetching, profileData, basicDetails, navigation, activeProfile } =
    useBranchDetailLayout();
  const { navigations, globalState } = useCommonLayout();
  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets?.HEADERLOGO
            : Assets?.HEADERLOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />
      <div
        className={`pro-w-100 container-fluid pt-custom ${profileStyle.main_wrapper}`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${profileStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(profileData?.data ?? {})?.length === 0 ? (
              <>
                <ProfileLayoutSidebarShimmer />
              </>
            ) : (
              <>
                <ProfileSideBar
                  extraClassName={`${profileStyle.left_sidebar_sticky} left-sidebar`}
                >
                  <div
                    className={`pro-pt-5 pro-d-flex pro-flex-column pro-h-100`}
                  >
                    <div className="pro-px-4">
                      <div className={`pro-border-bottom pro-pb-3 `}>
                        <Link to={`/branches`} className="pro-back-btn">
                          <span className="material-symbols-outlined">
                            {" "}
                            keyboard_arrow_left{" "}
                          </span>
                          {`Back`}
                        </Link>
                        <h3 className={`h3 pro-ttl pro-mb-0 pro-mt-3`}>
                          {profileData?.data?.name}
                          <span>{profileData?.data?.result?.order_num}</span>
                        </h3>
                      </div>
                      <div className={`${profileStyle.order_items_wrap}`}>
                        <MultiColumnTable
                          title={"Basic Details"}
                          data={basicDetails}
                          extraClassNames={`multicol-details_table`}
                        />
                      </div>
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>

          <div className={`${profileStyle.main_container} pro-pt-4 pro-ps-5`}>
            <NavGroup navigations={navigation} type={"type-2"} Link={Link} />
            <Outlet context={{ activeProfile }} />
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default BranchDetailLayout;
