import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGetDeclarationDetailsQuery } from "../../../../store/queries/remittance";
import { useState } from "react";

const useDeclarationInfo = () => {
  const dispatch = useDispatch();
  // const mainData = JSON.parse(localStorage?.getItem("remittanceDetails"));
  const { ID } = useParams();

  const { data: mainData, isFetching } = useGetDeclarationDetailsQuery({
    remittance_id: localStorage.getItem("remittance_id") ?? ID,
  });

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");

  const label = {
    date: "Date",
    name: "Name and address of AD branch/FFMC through which the transaction has been effected",
    amount: "Amount",
  };

  const value = {
    date:
      mainData !== null
        ? `${mainData?.data?.declaration_date ?? "Not specified"}`
        : "Not specified",
        name: `${mainData?.data?.name ?? "Not specified"}`,
        amount: `${mainData?.data?.amount ?? "Not specified"}`,
    
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  const handleViewImage = (item) => {
    setShowImageModal(() => true);
    setImageData(item);
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
  };

  return {
    basicDetails,
    mainData,
    handleViewImage,
    showImageModal,
    closeImageModal,
    imageData
  };
};

export default useDeclarationInfo;
