import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import useRemittances from "./useRemittances";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import ReasonModal from "./ReasonModal";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";
import Select from "react-select";
import { useEffect, useState } from "react";
import Style from "./remittances.module.scss";


const Remittances = ({ dashboard = false, mainFilter = "", subFilter = "" }) => {
  const {
    mainData,
    handleSearch,
    handleEditClick,
    isLoading,
    actionOptions,
    handleClearClick,
    currentPage,
    paginationOptions,
    menuState,
    isFetching,
    hasEditPermission,
    handleEditAction,
    hasCreatePermission,
    showCreateModal,
    handleSort,
    showEditModal,
    handleCreateClick,
    closeModal,
    updateTableFields,
    refetch,
    closeEditModal,
    handlePagination,
    getRow,
    handleActionChange,
    handlePageSize,
    basicData,
    showConfirmationModal,
    handleSubmitOrderComplete,
    closeConfirmationModal,
    handleSubmitPaymentReviewChange,
    closePaymentReasonModal,
    setStatusReason,
    orderPaymentReason,
    statusReason,
    errors,
    date,
    handleDateRangeChange,
    handleMainStatusFilter,
    handleSubStatusFilter,
    handleBranchFilter,
    handleDashboardRedirect
  } = useRemittances({ dashboard, mainFilter, subFilter });

  const [totalPageCount, setTotalPageCount] = useState(0);

  useEffect(() => {
    if (mainData?.data?.total_count) {
      const pageCount = Math.ceil(
        mainData?.data?.total_count / menuState?.currentPageSize
      );
      setTotalPageCount(pageCount);
    }
    //eslint-disable-next-line
  }, [mainData?.data]);
  return (
    <>
    {!dashboard && (
      <HeadingGroup
        title={"Remittance"}
        className={`pro-mb-4`}
        // buttonTitle={hasCreatePermission === 1 ? "Add new" : ""}
        // handleClick={handleCreateClick}
      />
    )}
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col">
          {!dashboard && (
          <SearchFilters
                // data={filters}
                // activeFilter={activeFilter}
                // handleButtonGroupChange={handleFilter}
                dropDownFilter={
                  <>
                    <div className="col-auto">
                      <CustomDateRangePicker
                        defaultDateRange={date}
                        handleDateRangeChange={handleDateRangeChange}
                      />
                    </div>

                    <div className="col-auto">
                      <Select
                        id="main_status"
                        isClearable={false}
                        placeholder={"Select "}
                        options={basicData?.data?.remittance_status}
                        getOptionValue={(option) => option?.value}
                        getOptionLabel={(option) => option?.name}
                        className={`pro-input lg multi-select`}
                        classNamePrefix="pro-input"
                        value={basicData?.data?.remittance_status?.filter(
                          (p) => p?.value === menuState?.currentMainStatusFilter
                        )}
                        onChange={(value) => handleMainStatusFilter(value)}
                      />
                    </div>
                    <div className="col-auto">
                      <Select
                        id="sub_status"
                        isClearable={true}
                        placeholder={"Select Purpose"}
                        options={basicData?.data?.remittance_purpose}
                        getOptionValue={(option) => option?.value}
                        getOptionLabel={(option) => option?.name}
                        className={`pro-input lg multi-select`}
                        classNamePrefix="pro-input"
                        value={basicData?.data?.remittance_purpose?.filter(
                          (p) => menuState?.currentSubStatusFilter === p?.value
                        )}
                        onChange={(value) => handleSubStatusFilter(value)}
                      />
                    </div>

                    <div className="col-auto">
                      <Select
                        id="branch"
                        isMulti
                        isClearable={true}
                        placeholder={"Select Branch"}
                        options={basicData?.data?.branches}
                        getOptionValue={(option) => option?._id}
                        getOptionLabel={(option) => option?.name}
                        className={`pro-input lg multi-select`}
                        classNamePrefix="pro-input"
                        value={basicData?.data?.branches?.filter((p) =>
                          menuState?.currentBranchFilter?.includes(p._id)
                        )}
                        onChange={(value) =>
                          handleBranchFilter(value.map((v) => v._id))
                        }
                      />
                    </div>
                  </>
                }
                initialDateRange={date}
                // onDateChange={handleDateChange}
                onSearchInput={handleSearch}
                showActions={false}
                handleActionClick={handleEditClick}
                loading={isLoading}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                actionOptions={
                  !dashboard ? actionOptions?.map((item) => item) : ""
                }
                onActionOptionChange={handleActionChange}
                handleClear={handleClearClick}
                searchInputProps={{ value: menuState?.search }}
              />
          )}
          </div>
        </div>

        {mainData?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <div
          onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
          className={!dashboard ? "pro-pt-3" : `${Style.dashboardTable}`}
        >
          <Table
            data={mainData?.data?.data || []}
            uniqueID={"branch_id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            showCheckBox={hasEditPermission === 1 ? true : false}
            deletable={false}
            handleEdit={handleEditAction}
            clear={menuState?.clearSelection}
            multiSelect={false}
            assignable={false}
            fields={mainData?.data?.fields}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={menuState?.currentPageSize}
          />
          </div>
        )}

        {mainData?.data?.data?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={totalPageCount}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(mainData?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = mainData?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={mainData?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showConfirmationModal}
          handleClose={closeConfirmationModal}
          backdrop="static"
          centered={true}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to complete this order?`}
              subTitle={`This action cannot be undone.`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={closeConfirmationModal}
              submitAction={handleSubmitOrderComplete}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={orderPaymentReason}
          handleClose={closePaymentReasonModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ReasonModal
              setStatusReason={setStatusReason}
              statusReason={statusReason}
              handleSubmitPaymentReviewChange={handleSubmitPaymentReviewChange}
              closeModal={closePaymentReasonModal}
              errors={errors}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default Remittances;
