import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { useDispatch } from "react-redux";
import Style from "./documentDetails.module.scss";
import { toast } from "react-toastify";
import { createDocRequest, updateConfig } from "../../../../store/slices/Remittance/remittanceSlice.js";
import ItemField from "./ItemFieldClick/index.js";
import * as Yup from "yup";
import { useFormik } from "formik";

const RequestForm = ({ setShowform, refetch, basicData }) => {
  const dispatch = useDispatch();

  const handleAddField = () => {
    let { required_documents } = formik?.values;
    required_documents = [
      ...required_documents,
      {
        document_type: "",
        comment: "",
      },
    ];
    formik.setFieldValue("required_documents", required_documents);
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const validationSchema = Yup.object({
    required_documents: Yup.array().of(
      Yup.object().shape({
        document_type: Yup.string().required("*Required"),
        comment: Yup.string().required("*Required"),
      })
    ),
  });

  const initialData = {
    required_documents: [
          {
            document_type: "",
            comment: ""
          },
        ],
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,

    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("remittance_id", localStorage.getItem("remittance_id"),)
      if (values?.required_documents?.length > 0) {
        values?.required_documents?.forEach((value, index) => {
          formData.append(`required_documents[${index}][document_type]`, value?.document_type);
          formData.append(`required_documents[${index}][comment]`, value?.comment);
        });
      }
      
            dispatch(createDocRequest(formData)).then((result) => {
                    if (result?.payload?.data?.success) {
                      refetch();
                      toast.success(result?.payload?.data?.message)
                      setShowform(false);
                    } else {
                      toast.error(result?.payload?.data?.required_documents?.[0]);
                    }
                  })
                  .catch((err) => {
                    Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
                      toast.error("Something went wrong!")
                    });
            });

      // Handle form submission here
    },
  });

  

  return (
    <div>
      {/* Render form fields for the "Address" tab */}
      <div className="col-12 pro-mb-4">
        <div className={`${Style.add_more_wrap}`}>
          {formik?.values?.required_documents?.map((fields, index) => (
            <ItemField
              key={index}
              itmIndex={index}
              fields={fields}
              formik={formik}
              getFieldError={getFieldError}
              basicData={basicData}
            />
          ))}
          <Button
            className={`pro-btn-outline lg pro-w-100 pro-mt-4`}
            onClick={handleAddField}
            type="button"
            // disabled={!tempFilteredData?.[0]?.qc_fields?.length}
          >
            {` Add More`}
          </Button>
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end pro-p-4 pro-pt-0 offcanvas-footer-sticky-btns
        }`}
      >
        <Button
          type="button"
          className={"pro-btn-link lg pro-px-3"}
          onClick={() => setShowform(false)}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default RequestForm;
