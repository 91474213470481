import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/currency/currencySlice";
import {
  createNewCurrency,
  updateCurrency,
} from "../../../../store/slices/currency/currencySlice";
import { toast } from "react-toastify";
import { useGetCurrencyDataQuery } from "../../../../store/queries/currency";

const useCurrencyForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();
  const [showIBR, setShowIBR] = useState(false);
  const profilefileInputRef = useRef(null);
  const { is_edit, selectedId, selectedItemsDetails } = useSelector(
    (state) => state.currency
  );
  const { data: formData = {} } = useGetCurrencyDataQuery();

  useEffect(() => {
    if (selectedItemsDetails !== "") {
      selectedItemsDetails?.currency_code?.currency_code === "USD" &&
        setShowIBR(true);
    }
  }, [selectedItemsDetails]);

  const validation = Yup.object({
    name: Yup.string().required("*Currency name is required"),
    currency_code: Yup.string().required("*Currency code is required"),
    sell_fixed_rate: Yup.number()
      .positive("Value must be a positive number")
      .required("*Sell fixed rate is required"),

    buy_fixed_rate: Yup.number()
      .positive("Value must be a positive number")
      .required("*Buy fixed rate is required"),

    forex_quantity_limit: Yup.number()
      .integer("Only whole numbers are allowed")
      .required("*forex quantity limit is required"),

    ibr_value: Yup.number().when("currency_code", {
      is: "USD",
      then: (schema) =>
        schema.required("IBR value is required for USD currency"),
    }),
    sha_charge: Yup.number()
      .positive("Value must be a positive number")
      .required("*SHA Rate is required"),
      our_charge: Yup.number()
      .positive("Value must be a positive number")
      .required("*Our Charge is required"),
      remittance_markup_rate: Yup.number()
      .positive("Value must be a positive number")
      .required("*Remittance markup rate is required"),
      remittance_fixed_rate: Yup.number()
      .positive("Value must be a positive number")
      .required("*Remittance fixed rate is required"),
  });

  const formik = useFormik({
    initialValues: {
      currency_id:
        selectedItemsDetails !== "" ? selectedItemsDetails?.name : "",
      name: selectedItemsDetails !== "" ? selectedItemsDetails?.name : "",
      currency_code:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.currency_code?.currency_code
          : "",
      buy_rate:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.currency_code?.buy_rate
          : "",
      sell_rate:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.currency_code?.sell_rate
          : "",
      sell_fixed_rate:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.sell_fixed_rate
          : "",
      sell_markup_value:
        selectedItemsDetails !== ""
          ? Number(
              Number(selectedItemsDetails?.currency_code?.sell_rate) -
                Number(selectedItemsDetails?.sell_fixed_rate)
            ).toFixed(4)
          : "",
      buy_fixed_rate:
        selectedItemsDetails !== "" ? selectedItemsDetails?.buy_fixed_rate : "",
      buy_markup_value:
        selectedItemsDetails !== ""
          ? Number(
              Number(selectedItemsDetails?.currency_code?.buy_rate) +
                Number(selectedItemsDetails?.buy_fixed_rate)
            ).toFixed(4)
          : "",
      forex_quantity_limit:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.forex_quantity_limit
          : "",
      status:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.status !== null
            ? selectedItemsDetails?.status
            : 1
          : 1,

      ibr_value:
        selectedItemsDetails !== "" ? selectedItemsDetails?.ibr_rate : "",
        sha_charge:
        selectedItemsDetails !== "" ? selectedItemsDetails?.sha_charge : "",
        our_charge:
        selectedItemsDetails !== "" ? selectedItemsDetails?.our_charge : "",
        remittance_markup_rate:
        selectedItemsDetails !== "" ? selectedItemsDetails?.remittance_fixed_rate : "",
        remittance_fixed_rate:
        selectedItemsDetails !== ""
          ? Number(
              Number(selectedItemsDetails?.remittance_fixed_rate) +
                Number(selectedItemsDetails?.currency_code?.buy_rate)
            ).toFixed(4)
          : "",
    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        currency_id: is_edit ? selectedId : "",
        name: values?.name,
        currency_code: values?.currency_code,
        buy_fixed_rate: values?.buy_fixed_rate,
        sell_fixed_rate: values?.sell_fixed_rate,
        forex_quantity_limit: values?.forex_quantity_limit,
        sha_charge: values?.sha_charge,
        our_charge: values?.our_charge,
        remittance_fixed_rate: values?.remittance_markup_rate,
        status: +values?.status,
      };

      if (values?.currency_code === "USD") {
        obj = {
          ...obj,
          ibr_rate: +values?.ibr_value,
        };
      }
      let formData = new FormData();
      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if (selectedItemsDetails !== "") {
        formData.append("_method", "PUT");
        dispatch(updateCurrency({ data: formData })).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
            setShowIBR(false);
            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            formik.setErrors(response?.payload?.data);
          } else toast.error(response?.payload?.message);
        });
      } else {
        dispatch(createNewCurrency(formData)).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            refetch();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            setShowIBR(false);
            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            formik.setErrors(response?.payload?.data);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });

  const handleCloseModal = () => {
    setShowIBR(false);
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const getCurrencyObj = (value) => {
    formik.setFieldValue("buy_rate", value?.buy_rate);
    formik.setFieldValue("sell_rate", value?.sell_rate);
  };

  const handleChangeCurrencyCode = (value) => {
    formik?.setFieldValue("currency_code", value?.currency_code || null);
    getCurrencyObj(value);

    if (value?.currency_code === "USD") {
      setShowIBR(true);
    } else {
      setShowIBR(false);
      formik?.setFieldValue("ibr_value", "");
    }
  };

  const handleChangeBuyForexRate = (event) => {
    formik?.setFieldValue("buy_fixed_rate", event.target.value);
    if (event?.target?.value && formik?.values?.buy_rate) {
      const BuyMarkupValue = Number(
        Number(formik?.values?.buy_rate) + Number(event?.target?.value)
      ).toFixed(4);
      formik?.setFieldValue("buy_markup_value", Number(BuyMarkupValue));
    } else {
      formik?.setFieldValue("buy_markup_value", "");
    }
  };

  const handleChangeSellForex = (event) => {
    formik?.setFieldValue("sell_fixed_rate", event.target.value);
    if (event?.target?.value && formik?.values?.sell_rate) {
      const SellMarkupValue = Number(
        Number(formik?.values?.sell_rate) - Number(event?.target?.value)
      ).toFixed(4);
      formik?.setFieldValue("sell_markup_value", Number(SellMarkupValue));
    } else {
      formik?.setFieldValue("sell_markup_value", "");
    }
  };

  const handleChangeRemittanceRate = (event) => {
    formik?.setFieldValue("remittance_markup_rate", event.target.value);
    if (event?.target?.value && formik?.values?.buy_rate) {
      const RemittanceFixedValue = Number(Number(formik?.values?.buy_rate) + Number(event?.target?.value)).toFixed(4);
      formik?.setFieldValue("remittance_fixed_rate", Number(RemittanceFixedValue));
    } else {
      formik?.setFieldValue("remittance_fixed_rate", "");
    }
  };

  return {
    formik,
    profilefileInputRef,
    selectedItemsDetails,
    formData,
    showIBR,
    handleChangeCurrencyCode,
    handleChangeSellForex,
    handleChangeBuyForexRate,
    handleCloseModal,
    handleChangeRemittanceRate
  };
};

export default useCurrencyForm;
